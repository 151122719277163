<template>
    <div class="container search-box">
        <div class="search-box-title">
            {{ $t('customization.title') }}
        </div>
        <div class="search-box__content flex flex-col">
            <div class="search-box__row">
                <CustomSelect size="lg" :add-border="true" :options="listOfCountry" :selectedValue="selectedCountryObject.value" @update:selectedOption="changeSelectedCountry">
                    <template #customSelectedTemplate>
                        <div>
                            <span v-if="!selectedCountryObject?.noIcon" :class="'flag-icon flag-icon-' + selectedCountryObject.ISO"></span>
                            <span>{{ t(`countries.${selectedCountryObject.value}`) }}</span>
                        </div>
                    </template>

                    <template #optionContent="{ option }">
                        <div class="flex justify-between items-center px-2">
                            <span>{{ t(`countries.${option.value}`) }}</span>
                            <span v-if="!option?.noIcon" :class="'flag-icon flag-icon-' + option.ISO"></span>
                        </div>
                    </template>
                </CustomSelect>
                <div class="search-result-container">
                    <div class="search-result relative">
                        <label class="input-prefix text-white absolute" for="number">{{ currency?.symbol }}</label>
                        <input
                            :value="convertedCountryCount"
                            @input="setCountryCountFromInput($event.target.value)"
                            class="search-result-input text-3xl"
                            type="number"
                            min="0.01"
                            step="0.01"
                            id="sum" />
                    </div>

                    <label class="input-sufix">{{ $t('customization.kWh') }}</label>
                </div>
            </div>

            <div class="search-box__row">
                <CustomSelect size="lg" class="" :options="storeSizeOptions" :selectedValue="selectedStoreSize" @update:selectedOption="changeStoreSize">
                    <template #customSelectedTemplate>
                        <div>
                            {{ t(`customization.storeSize.${selectedStoreSize}`) }}
                        </div>
                    </template>
                    <template #optionContent="{ option }">
                        <div class="flex justify-between items-center px-2">
                            {{ t(`customization.storeSize.${option.value}`) }}
                        </div>
                    </template>
                </CustomSelect>
                <div class="search-result-container lifetime-block">
                    <span class="text-3xl text-white lifetime-text">{{ $t('customization.equipmentLifeTime') }}</span>

                    <CustomSelect size="md" class="" :options="lifetimeOptions" :selectedValue="selectedLifetime" @update:selectedOption="changeLifetime"></CustomSelect>

                    <label class="input-sufix">{{ $t('customization.years') }}</label>
                </div>
            </div>
        </div>
    </div>

    <div class="container content-wrapper">
        <div
            class="container convenience-store-wrap"
            v-bind:style="{
                backgroundImage: `url(${selectedImagePath})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }">
            <div class="store-image-overlay"></div>
        </div>

        <footer class="footer" id="footer">
            <div class="container energy-bill">
                <div class="row">
                    <div class="energy-bill-title">{{ $t('computing.bill') }}</div>

                    <div class="cabinets-wrap pink">
                        <div class="left-side">
                            <div class="label">{{ $t('computing.classicOpen') }}</div>
                            {{ classicOpenCabinetsPower }} {{ $t('customization.kW') }}
                        </div>
                        <div class="right-side">
                            <span class="currency-symbol">{{ currency?.symbol }} </span> {{ convertedClassicOpenCabinetsAWG }}
                        </div>
                    </div>

                    <div class="cabinets-wrap waterspout">
                        <div class="left-side">
                            <div class="label">{{ $t('computing.classicWithDoors') }}</div>
                            {{ classicOpenCabinetsWsDorPower }} {{ $t('customization.kW') }}
                        </div>
                        <div class="right-side">
                            <span class="currency-symbol">{{ currency?.symbol }} </span> {{ convertedClassicOpenCabinetsWsDorAWG }}
                        </div>
                    </div>

                    <div class="cabinets-wrap light-green">
                        <div class="left-side">
                            <div class="label">{{ $t('computing.energyEfficient') }}</div>
                            {{ energyEfficientPower }} {{ $t('customization.kW') }}
                        </div>
                        <div class="right-side">
                            <span class="currency-symbol">{{ currency?.symbol }} </span> {{ convertedEnergyEfficientAWG }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row energy-efficient">
                    <div class="energy-efficient-title font-medium">
                        {{ $t('footer.saveUp') }}
                    </div>
                    <div class="energy-efficient__wrapper">
                        <div class="energy-efficient__wrapper__calculation-block">
                            <div class="label-and-cost">
                                <div class="label">{{ $t('footer.from') }}</div>
                                <div class="cost">
                                    <span class="currency-symbol">{{ currency?.symbol }} </span>{{ minSaveToShow }}
                                </div>
                            </div>
                            <div class="period">{{ $t('footer.paybackPeriod') }} {{ minTime }} {{ $t('footer.years') }}</div>
                            <div class="comparing">{{ $t('footer.comparingToClassic') }}</div>
                        </div>
                        <div class="energy-efficient__wrapper__calculation-block">
                            <div class="label-and-cost">
                                <div class="label">{{ $t('footer.upTo') }}</div>
                                <div class="cost">
                                    <span class="currency-symbol">{{ currency?.symbol }} </span>{{ maxSaveToShow }}
                                </div>
                            </div>
                            <div class="period">{{ $t('footer.paybackPeriod') }} {{ maxTime }} {{ $t('footer.years') }}</div>
                            <div class="comparing">{{ $t('footer.comparingToClassicOpen') }}</div>
                        </div>
                    </div>
                    <div class="energy-efficient-title font-normal">
                        {{ $t('footer.inEveryStore') }}
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

import { LIST_OF_COUNTRY } from '@/components/const/list-of-country';
import { ref, defineProps, computed, watch, onMounted, onUnmounted } from 'vue';
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue';

const { t, locale } = useI18n();

const props = defineProps({
    currency: {
        type: Object
    }
});

const listOfCountry = LIST_OF_COUNTRY;

const selectedCountryCount = ref(0.2);
const selectedCountryObject = ref(listOfCountry[0]);

const changeSelectedCountry = country => {
    selectedCountryCount.value = country.Price;
    selectedCountryObject.value = country;
};

const setCountryCountFromInput = value => {
    selectedCountryCount.value = value;
};

const convertedCountryCount = computed(() => {
    return convertCurrency(selectedCountryCount.value, false);
});

const lifetimeOptions = Array.from({ length: 11 }, (_, i) => {
    const year = i + 5;
    return { value: year, label: String(year) };
});

const selectedLifetime = ref(lifetimeOptions[7].value);

const changeLifetime = event => {
    selectedLifetime.value = Number(event.value);
};

const storeSizeOptions = [
    { value: 0, label: t('customization.storeSizeSelect') },
    { value: '50', label: t('customization.storeSize.4cabinets') },
    { value: '100', label: t('customization.storeSize.8cabinets') },
    { value: '200', label: t('customization.storeSize.12cabinets') }
];

const selectedStoreSize = ref(storeSizeOptions[0].value);

const changeStoreSize = event => {
    selectedStoreSize.value = event.value;
    updateImagePath();
};
const selectedImagePath = ref('');

const updateImagePath = () => {
    let resolution = '1080';
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1920) {
        resolution = '1920';
    } else if (screenWidth >= 1440) {
        resolution = '1440';
    } else if (screenWidth <= 800) {
        resolution = '375';
    }
    selectedImagePath.value = require(`@/assets/images/img/storeSize/${locale.value}/${resolution}/${selectedStoreSize.value || 50}.png`);
};

let resizeObserver = null;
onMounted(() => {
    updateImagePath();

    resizeObserver = new ResizeObserver(updateImagePath);
    resizeObserver.observe(document.body);
});

onUnmounted(() => {
    if (resizeObserver) {
        resizeObserver.disconnect();
    }
});

const classicOpenCabinetsPower = computed(() => {
    switch (Number(selectedStoreSize.value)) {
        case 50:
            return '645 305';
        case 100:
            return '1082 867';
        case 200:
            return '1440 013';
        default:
            return 0;
    }
});

const classicOpenCabinetsWsDorPower = computed(() => {
    switch (Number(selectedStoreSize.value)) {
        case 50:
            return '340 195';
        case 100:
            return '527 045';
        case 200:
            return '677 236';
        default:
            return 0;
    }
});

const energyEfficientPower = computed(() => {
    switch (Number(selectedStoreSize.value)) {
        case 50:
            return '126 538';
        case 100:
            return '176 996';
        case 200:
            return '221 146';
        default:
            return 0;
    }
});

const classicOpenCabinetsAWG = computed(() => calculateAWG(classicOpenCabinetsPower.value) || 0);
const classicOpenCabinetsWsDorAWG = computed(() => calculateAWG(classicOpenCabinetsWsDorPower.value) || 0);
const energyEfficientAWG = computed(() => calculateAWG(energyEfficientPower.value) || 0);

const convertedClassicOpenCabinetsAWG = computed(() => convertCurrency(classicOpenCabinetsAWG.value).toLocaleString('ua'));
const convertedClassicOpenCabinetsWsDorAWG = computed(() => convertCurrency(classicOpenCabinetsWsDorAWG.value).toLocaleString('ua'));
const convertedEnergyEfficientAWG = computed(() => convertCurrency(energyEfficientAWG.value).toLocaleString('ua'));

const minSave = computed(() => {
    return Math.round(classicOpenCabinetsWsDorAWG.value - energyEfficientAWG.value);
});

const maxSave = computed(() => {
    return Math.round(classicOpenCabinetsAWG.value - energyEfficientAWG.value);
});

const minSaveToShow = computed(() => {
    return minSave.value.toLocaleString('ua');
});

const maxSaveToShow = computed(() => {
    return maxSave.value.toLocaleString('ua');
});

const minSaleInEuro = computed(() => {
    switch (Number(selectedStoreSize.value)) {
        case 50:
            return 4128;
        case 100:
            return 6908;
        case 200:
            return 9909;
        default:
            return 0;
    }
});

const maxSaleInEuro = computed(() => {
    switch (Number(selectedStoreSize.value)) {
        case 50:
            return 4316;
        case 100:
            return 7043;
        case 200:
            return 10245;
        default:
            return 0;
    }
});

const minTime = computed(() => {
    const sale = convertCurrency(minSaleInEuro.value);
    return Math.round(sale / (minSave.value / selectedLifetime.value / 12)) || 0;
});

const maxTime = computed(() => {
    const sale = convertCurrency(maxSaleInEuro.value);
    return Math.round(sale / (maxSave.value / selectedLifetime.value / 12)) || 0;
});

const calculateAWG = power => {
    if (power) {
        const result = Number(power.replace(/\s+/g, '')) * selectedCountryCount.value;
        return result.toFixed(2);
    }
};

const exchangeRates = {
    EUR: 1, // Base currency
    UAH: 44.85,
    USD: 1.08,
    PLN: 4.23
};

const convertCurrency = (amount, toRound = true) => {
    const currencyCode = props.currency?.value || 'EUR';
    const rate = exchangeRates[currencyCode] || 1;
    return toRound ? Math.round(amount * rate) : (amount * rate).toFixed(2);
};

watch(
    () => props.currency,
    (newValue, oldValue) => {
        if (newValue !== oldValue) {
            selectedCountryCount.value = convertCurrency(selectedCountryObject.value.Price, false);
        }
    },
    { immediate: true }
);

watch(
    () => locale.value,
    (newValue, oldValue) => {
        if (newValue !== oldValue) {
            updateImagePath();
        }
    },
    { immediate: true }
);
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
}

.search-box {
    padding: 19px 29px 19px 40px;
    background: #000000;

    &__content {
        display: flex;
        gap: 30px;
    }

    &__row {
        display: flex;
        gap: 40px;
        justify-content: space-between;
        align-items: center;
    }

    &-title {
        font-size: 34px;
        font-weight: 600;
        line-height: 39.84px;
        text-align: left;
        color: #ffffff;
        text-transform: uppercase;
        margin-bottom: 14px;

        span {
            text-transform: initial;
        }
    }

    .dropdown {
        width: 100% !important;
        height: 80px;
    }
}

.convenience-store-wrap {
    background: #9c9c9c;
    border: 2px solid #000000;

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    height: 547px;
}

.search-result {
    background: #c6cacb;
    border-radius: 6px;
    max-width: 215px;
    font-weight: 500;
    font-size: 32px;
    line-height: 33px;
    display: flex;
    align-items: center;
}

.search-result-container {
    display: flex;
    align-items: center;
    width: 30%;
}

.lifetime-block {
    display: flex;
    position: relative;
    align-items: center;

    .lifetime-text {
        position: absolute;
        bottom: 85px;
        white-space: nowrap;
        font-size: 20px;
        font-weight: 500;
        line-height: 33px;
        text-align: left;
    }
}

.input-sufix {
    color: #fff;
    font-weight: 500;
    font-size: 32px;
    line-height: 33px;
    margin-bottom: 0;
    margin-left: 17px;
}

.search-result-input {
    padding-left: 40px;
    background: var(--background-color);
    border: 2px solid var(--background-color);
    outline: none;
    border-radius: 6px;
    width: 160px;
    height: 88px;

    &:focus,
    &:active {
        border: 2px solid #c6cacb !important;
    }
}

.input-prefix {
    margin-left: 10px;
    color: #000 !important;
}

.energy-bill {
    &-title {
        padding-top: 12px;
        padding-bottom: 6px;
        font-weight: 500;
        text-align: center;
        color: #000000;
        font-size: 24px;
        line-height: 28.13px;
    }

    .cabinets-wrap {
        align-items: center;
        padding-top: 13px;
        padding-bottom: 9px;
        justify-content: space-between;
        display: flex;
        -webkit-box-align: center;

        .left-side {
            font-weight: 500;
            font-size: 62px;
            line-height: 73px;
            padding-left: 16px;

            .label {
                white-space: nowrap;
                font-weight: 500;
                font-size: 36px;
                line-height: 42px;
            }
        }

        .right-side {
            font-weight: 800;
            font-size: 84px;
            line-height: 98px;
            padding-right: 22px;
        }

        &.pink {
            background: #fea4ee;
        }

        &.waterspout {
            background: #a3effb;
        }

        &.light-green {
            background: #a3e474;
        }
    }
}

.energy-efficient {
    border-top: 10px solid #000;
    padding-top: 16px;
    padding-bottom: 18px;

    &-title {
        font-size: 26px;
        font-weight: 500;
        line-height: 30.47px;
        text-align: center;
    }

    &__wrapper {
        display: flex;

        &__calculation-block {
            padding-left: 15%;
            flex: 0 0 50%;
            max-width: 50%;

            .label {
                white-space: nowrap;
                font-weight: 600;
                font-size: 36px;
                line-height: 1;
            }

            .cost {
                font-weight: 700;
                font-size: 80px;
                line-height: 1;
            }

            .period {
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
            }

            .comparing {
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                color: #606060;
                max-width: 300px;
            }
        }
    }
}

.content-wrapper {
    display: flex;
    flex-direction: column;
}

@media (min-width: 1440px) {
    .container {
        max-width: 100%;
    }
    .content-wrapper {
        flex-direction: row;
    }
    .convenience-store-wrap {
        border-top: none;
        border-bottom: none;
        flex: 0 0 50%;

        height: 645px;
    }
    footer {
        flex: 0 0 50%;
    }

    .energy-bill {
        &-title {
            padding-top: 33px;
            padding-left: 21px;
            font-weight: 500;
            text-align: left;
            font-size: 20px;
            line-height: 23.44px;
        }

        .cabinets-wrap {
            padding-left: 22px;
            padding-top: 9px;
            padding-bottom: 0;
            justify-content: unset;

            .left-side {
                font-size: 52px;
                font-weight: 500;
                line-height: 60.94px;
                text-align: left;
                padding-left: 0;

                flex: 0 0 45%;

                .label {
                    white-space: nowrap;
                    font-size: 30px;
                    line-height: 35px;
                }
            }

            .right-side {
                margin-left: auto;
                font-size: 72px;
                line-height: 84.38px;
                white-space: nowrap;
            }
        }
    }

    .energy-efficient {
        padding: 22px 0 7px 21px;
        border-top: 7px solid #000;

        .label-and-cost {
            align-items: baseline;
            display: flex;
            gap: 10px;
        }

        &-title {
            margin-top: 0;
            text-align: left;
            font-weight: 500;
            font-size: 18px;
            line-height: 21.09px;
        }

        &__wrapper {
            justify-content: space-between;
            margin-top: 14px;
            margin-bottom: 17px;

            &:last-child {
                padding-left: 6%;
            }

            &__calculation-block {
                flex-basis: auto;
                padding-left: 0;
                max-width: unset;

                .period {
                    font-weight: 500;
                }

                .comparing {
                    max-width: unset;
                }

                .cost {
                    font-size: 66px;
                }

                .period {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 28px;
                }

                .comparing {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 20px;
                    color: #606060;
                    max-width: 300px;
                }
            }
        }
    }

    .search-box {
        height: 179px;

        font-size: 32px;

        &-title {
            font-size: 34px;
            line-height: 39.84px;

            margin-bottom: 10px;
        }

        &__content {
            flex-direction: row;
            gap: 28px;
        }

        &__row {
            .search-result-container {
                width: unset;
            }

            .search-result-input {
                height: 83px;
                width: 160px;
            }
        }
    }
}

@media (min-width: 1920px) {
    .container {
        max-width: 100%;
    }
    .content-wrapper {
        flex-direction: row;
    }
    .convenience-store-wrap {
        border-top: none;
        border-bottom: none;
        flex: 0 0 50%;

        height: 701px;
    }
    footer {
        flex: 0 0 50%;
    }

    .energy-efficient {
        padding: 19px 7px 7px 23px;
        border-top: 7px solid #000;

        .label-and-cost {
            align-items: baseline;
            display: flex;
            gap: 10px;
        }

        &-title {
            font-size: 24px;
            font-weight: 500;
            line-height: 28.13px;
            text-align: left;
        }

        &__wrapper {
            justify-content: space-between;
            max-width: 100%;

            &__calculation-block {
                width: 50%;
                flex-basis: auto;
                padding-left: 0;
                max-width: unset;

                &:last-child {
                    padding-left: 5%;
                }

                .cost {
                    font-size: 80px;
                }

                .period {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 28px;
                }

                .comparing {
                    max-width: unset;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 20px;
                    color: #606060;
                }
            }
        }
    }

    .search-box {
        height: 179px;
        font-size: 32px;

        &-title {
            font-size: 34px;
            margin-bottom: 10px;
        }

        &__content {
            flex-direction: row;
            gap: 50px;
        }

        &__row {
            &:last-child {
                flex: 0 0 50%;
            }
        }
    }
}

@media (max-width: 800px) {
    header {
    }
    .container {
        .search-box {
            font-size: 14px;
            padding: 9px 12px;

            &__content {
                gap: 18px;
            }

            &-title {
                font-size: 18px;
                margin-bottom: 6px;
                font-weight: 600;
                line-height: 21.09px;
                text-align: left;
            }

            &__row {
                gap: unset;

                .search-result {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.41px;
                }

                .search-result-container {
                    gap: 10px;
                }

                .search-result-input {
                    font-size: 14px;
                    padding-left: 20px;
                    height: 28px;
                    width: 60px;
                }

                .input-sufix,
                .input-prefix {
                    font-size: 14px;
                }

                .input-sufix {
                    margin-left: 0;
                }

                .lifetime-text {
                    top: -20px;
                    font-size: 9px;
                }
            }
        }

        .energy-bill {
            padding-top: 0;
            font-size: 11px;
            font-weight: 500;
            line-height: 12.89px;
            text-align: left;

            .cabinets-wrap {
                padding-top: 6px;
                padding-left: 12px;
                height: 50px;
            }

            &-title {
                padding-top: 6px;
                padding-bottom: 2px;
                padding-left: 12px;
                font-size: 8px;
                font-weight: 500;
                line-height: 9.38px;
                text-align: left;
            }

            .left-side {
                font-size: 24px;
                font-weight: 500;
                line-height: 28.13px;
                text-align: left;
                padding-left: 0;

                .label {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 17.58px;
                    text-align: left;
                }
            }

            .right-side {
                font-size: 36px;
                font-weight: 800;
                line-height: 42.19px;
                text-align: right;
                white-space: nowrap;
            }
        }
    }

    .energy-efficient {
        border-top: 3px solid #000;
        padding: 8px 12px;

        &-title {
            font-size: 9px;
            font-weight: 500;
            line-height: 10.55px;
            text-align: left;
            white-space: nowrap;

            &:last-child {
                margin-top: 4px;
            }
        }

        &__wrapper {
            padding-top: 4px;

            &__calculation-block {
                width: 50%;
                flex-basis: auto;
                padding-left: 0;
                max-width: unset;

                .label-and-cost {
                    display: flex;
                    align-items: baseline;
                    gap: 10px;

                    .label {
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 17.58px;
                        text-align: left;
                    }

                    .cost {
                        font-size: 34px;
                        font-weight: 700;
                        line-height: 39.84px;
                        text-align: left;
                    }
                }

                .period {
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 11.72px;
                    text-align: left;
                }

                .comparing {
                    max-width: unset;
                    font-size: 8px;
                    font-weight: 500;
                    line-height: 9.38px;
                    text-align: left;
                }

                .label {
                }
            }
        }
    }

    .convenience-store-wrap {
        height: 205px;
    }

    .search-box {
        padding: 9px 12px;
    }
}
</style>
