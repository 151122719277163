<template>
    <div class="information-wrapper">
        <img @click="closeInfoPopup" src="../assets/images/img/close.png" alt="Close" class="absolute top-0 right-0 close cursor-pointer z-50" width="77" height="77" />
        <div class="scroll-wrap">
            <div class="inner-wrap">
                <div class="uppercase flex flex-col font-extrabold mb-2 text-wrapper">
                    <span class="text-white title">
                        {{ $t('common.title') }}
                    </span>
                    <span class="info">
                        {{ $t('popup.information') }}
                    </span>
                </div>
                <div class="content-wrap">
                    <ul class="list-disc">
                        <li>{{ $t('popup.list.first') }}</li>
                        <li>{{ $t('popup.list.second') }}</li>
                        <li>{{ $t('popup.list.third') }}</li>
                        <li>{{ $t('popup.list.fourth') }}</li>
                        <li>{{ $t('popup.list.fifth') }}</li>
                    </ul>
                    <div class="two-list-wrapper">
                        <div class="sources-wrap border border-[#A3E474]">
                            <div class="title uppercase">{{ $t('popup.sources.title') }}</div>
                            <ol>
                                <li v-for="(source, index) in sources" :key="index">
                                    <a :href="source.url">
                                        {{ index + 1 }}. <span class="underline">{{ source.label }}</span>
                                    </a>
                                </li>
                            </ol>
                        </div>

                        <div class="bottom-wrap">
                            {{ $t('popup.conclusions') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['close']);
const { t } = useI18n();

const closeInfoPopup = () => {
    emit('close');
};

const sources = computed(() => [
    { label: t('popup.sources.first'), url: 'https://www.optimised.net' },
    { label: t('popup.sources.second'), url: 'https://ec.europa.eu' },
    { label: t('popup.sources.third'), url: 'https://www.globalpetrolprices.com' }
]);
</script>

<style lang="scss">
.information-wrapper {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    z-index: 999;
    top: 0;
    height: 100%;
    background-color: var(--dark);
    color: #a3e474;
}

.inner-wrap {
    padding: 50px 15px;
    position: relative;
    max-width: 1080px;
    margin: 0 auto;
}

.scroll-wrap {
    padding: 0 0 10px 0;
    width: 100%;
    margin-bottom: 35px;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: #a3e474 rgba(163, 228, 116, 0.2);
}

.content-wrap {
    margin-bottom: 15px;
    margin-left: 40px;
    margin-top: 40px;

    li {
        position: relative;
        padding-left: 37px;
        margin-bottom: 30px;
        font-weight: 500;
        font-size: 48px;
        line-height: 56px;
        color: #a3e474;
    }
}

.sources-wrap {
    border: 1px solid #a3e474;
    padding: 22px 60px;
    margin-bottom: 30px;

    .title {
        font-weight: 800;
        font-size: 36px;
        line-height: 42px;
        text-transform: uppercase;
    }

    ol {
    }
}

.bottom-wrap {
    border: 1px solid #ffffff;
    padding: 32px 42px;
    color: white;
    font-size: 36px;
}

.info,
.title {
    font-size: 96px;
    font-weight: 800;
    line-height: 112.5px;
    text-align: left;
}

@media (min-width: 1440px) and (max-width: 1919px) {
    .info,
    .title {
        font-size: 68px;
        font-weight: 800;
        line-height: 91.41px;
        text-align: left;
    }
    .inner-wrap {
        padding: 27px 43px;
        max-width: 100%;
    }
    .text-wrapper {
        flex-direction: row !important;
        gap: 30px;
        text-align: left;
    }

    .two-list-wrapper {
        display: flex;
        gap: 20px;

        .sources-wrap {
            width: 614px;
            padding-left: 47px;
            padding-top: 68px;
            margin-bottom: 0;

            li {
                padding-left: 47px;
                font-size: 34px;
                font-weight: 500;
                line-height: 42.19px;
                text-align: left;
            }
        }
    }
}

@media (min-width: 1920px) {
    .inner-wrap {
        padding: 43px 56px;
        max-width: 100%;
    }
    .text-wrapper {
        flex-direction: row !important;
        gap: 69px;
    }

    .two-list-wrapper {
        display: flex;
        gap: 20px;

        .sources-wrap {
            width: 614px;
            padding-left: 47px;
            padding-top: 68px;
            margin-bottom: 0;

            li {
                padding-left: 47px;
                font-size: 34px;
                font-weight: 500;
                line-height: 42.19px;
                text-align: left;
            }
        }
    }
}

@media (max-width: 800px) {
    .info,
    .title {
        font-size: 30px;
        font-weight: 800;
        line-height: 35.16px;
        text-align: left;
    }

    .content-wrap {
        padding: 0 20px;
        margin: 20px 0;

        li {
            padding: 0;
            font-size: 17px;
            font-weight: 500;
            line-height: 19.92px;
            text-align: left;
        }
    }

    .sources-wrap,
    .bottom-wrap {
        padding: 9px 23px;
        font-size: 15px;
        font-weight: 500;
        line-height: 17.58px;
        text-align: left;

        .title {
            font-size: 15px;
        }

        li {
            margin-bottom: 10px;
        }
    }
}
</style>
